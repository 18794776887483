<template>
  <!-- <dv-full-screen-container> -->
    <!-- 内容 -->
    <div class="home-content">
      <!-- 标题 -->
      <div class="title-layout">
        <div class="title-bg"></div>
        <div class="title">海都水产设备状态展示</div>
      </div>
      <!-- 设备信息 -->
      <div class="header-layout">
        <div class="header-box-item" v-for="(item, index) in tabs" :key="index">
          <div class="box-item-img">
            <img :src="item.icon" />
          </div>
          <div class="box-item-info">
            <div class="box-item-title">{{ item.title }}</div>
            <div class="box-item-count">{{ item.count }}</div>
          </div>
        </div>
      </div>
      <!-- 设备列表  -->
      <scroll-view  scroll-y="true" class="scrollview-layout">
      <div class="devices-layout"> 
          <div class="device-box-item" :style="backgroundStyle()" v-for="(item,index) in deviceList" :key="index">
          <label class="device-name">{{item.name}}</label>
          <label class="device-item-title">当前温度：{{item.currTemp}}</label>
          <label class="device-item-title">设置温度：{{item.setTemp}}℃</label>
          <label class="device-item-title">状态：{{getDeviceStatus(item)?.statusTitle}}</label>
          <div class="device-item-status">
            <img :src="getDeviceStatus(item)?.statusBg"/>
          </div>
        </div> 
      </div>
    </scroll-view>
    <audio ref="audio" :src="audioUrl" :muted="muted"></audio>
   <div class="audio-img" @click="changeMuted">
    <img
      class="audio-img"
      :src="muted ? require('../assets/img/guanbiyinpin.png'):require('../assets/img/yinpinaudio.png')"
      mode="scaleToFill"
    />
   </div>
   <!-- 当前时间 -->
    <div class="currentTime-layout">
       <label class="time-text">{{ currentTime }}</label>
    </div>
    </div>
  <!-- </dv-full-screen-container> -->
</template>

<script>
import { DeviceInfoApi } from '../api/api'
export default {
  name: 'HomeView',
  props: {

  },
  data() {
    return {
      tabs: [
        {
          'icon': require('../assets/img/icon04.png'),
          'title': '总台数',
          'count': 0
        },
        {
          'icon': require('../assets/img/icon03.png'),
          'title': '正常台数',
          'count': 0
        },
        {
          'icon': require('../assets/img/icon02.png'),
          'title': '离线台数',
          'count': 0
        },
        {
          'icon': require('../assets/img/icon01.png'),
          'title': '故障台数',
          'count': 0
        },
      ],
      deviceList:[],
      timer: null,
      audioUrl: require('../assets/img/audio.mp3'),
      muted:true,
      currentTime:''
    }
  },
  created(){
    this.getDeviceInfo();
    this.timer = setInterval(this.getDeviceInfo, 60000);
    this.updateTime(); // 更新一次时间
    setInterval(this.updateTime, 1000); // 每秒更新一次时间
  },
  methods:{
    // 获取设备信息
    getDeviceInfo(){
        DeviceInfoApi().then((res)=>{
          if(res?.code === '00000'){
            let data = JSON.parse(res.data);
            // 设备总数
            this.tabs[0].count = data.total;
            // 正常设备总数
            this.tabs[1].count = data.normalTotal;
             // 离线设备总数
             this.tabs[2].count = data.offlineTotal;
             // 故障设备总数
             this.tabs[3].count = data.faultTotal;
             // 设备列表
              this.deviceList = data.deviceList;

              // 遍历设备列表是否有故障
              for (let index = 0; index < this.deviceList.length; index++) {
                const element = this.deviceList[index];
                if(element.currFaultFlag == true){ 
                  if(!this.muted){
                   this.playAudio();
                }
                }
              }
              
          }else{
            this.$message(res.msg);
            this.$router.push('/login');
          }
            
        })
    },
     // 根据状态设置状态和背景等
     getDeviceStatus(item){
      if(item.currFaultFlag == true){ // 设备故障
        return {
          "statusTitle":'故障',
          "statusBg":require('../assets/img/icon_red.png'),
        };
       }else{  // 没有故障
        if(item.isOnline == false){ // 未开设备
         return {
          "statusTitle":'离线',
          "statusBg":require('../assets/img/icon_gary.png'),
        };
       } else if(item.isOnline == true){ // 设备正常打开
        return {
          "statusTitle":'正常',
          "statusBg":require('../assets/img/icon_geen.png'),
        };
       }
       }
     },
     // 背景样式
     backgroundStyle() {
      return {
        background: `url(${require('../assets/img/box_normal_bg.png')}) no-repeat center center`,
        backgroundSize: 'cover', // 根据需要调整,
      };
    },
    // 播放音频
  playAudio() {
      this.$refs.audio.play()
    },
    // 打开和关闭音频
    changeMuted(){
       this.muted = !this.muted;
    },
    // 更新时间
    updateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = this.padNumber(now.getMonth() + 1);
      const day = this.padNumber(now.getDate());
      const hours = this.padNumber(now.getHours());
      const minutes = this.padNumber(now.getMinutes());
      const seconds = this.padNumber(now.getSeconds());
       this.currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      padNumber(num) {
      return num < 10 ? '0' + num : num;
    }
  },
  beforeDestroy() {
    // 清除定时器，避免内存泄漏
    clearInterval(this.timer);
  }
}
</script>

<style scoped>
.home-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.title-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title-bg {
  width: 100%;
  height: 2.1125rem;
  background: url('../assets/img/title_bg.png') no-repeat center center;
  background-size: contain;
}

.title {
  position: absolute;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: .6375rem;
  color: #FFFFFF;
  line-height: .35rem;
  background: linear-gradient(0deg, rgba(145, 220, 255, 0.07) 0%, rgba(109, 191, 233, 0) 50%, rgba(130, 204, 255, 0.07) 100%);
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.header-layout {
  height: 1.775rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: url('../assets/img/header_bg.png') no-repeat center center;
  background-size: contain;
  margin: 0 2.025rem;
  padding-top: .2625rem;
  padding-bottom: .1625rem;
}

.header-box-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.box-item-img {
  width: 1.4rem;
  height: 1.35rem;
}

.box-item-img img {
  width: 100%;
  height: 100%;
}

.box-item-info {
  padding-left: .125rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.box-item-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: .3rem;
  color: #A1E7FF;
  line-height: .3rem;
}

.box-item-count {
  padding-top: .25rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: .6rem;
  color: #FFFFFF;
  line-height: .6rem;
}

.box-item-count::after {
  content: '台';
  padding-left: .0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: .3rem;
  color: #FFFFFF;
  line-height: .225rem;
}

.devices-layout {
  flex: 1;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3.1125rem, 1fr));
  gap: .125rem;
  padding: .375rem;
 
}

.device-box-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: .2125rem .25rem;
  height: 2.0625rem;
  /* background: url('../assets/img/box_normal_bg.png') no-repeat center center; */
  background-size: cover;
  border-radius: .125rem;
  overflow: hidden;
  position: relative;
}

.device-name {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: .3rem;
  color: #A1E7FF;
  line-height: .3rem;
  padding-bottom: .125rem;
}

.device-item-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: .275rem;
  color: #FFFFFF;
  line-height: .375rem;
}
.device-item-status{
   position: absolute;
   bottom: .175rem;
   right: .175rem;
   width: .425rem;
   height: .425rem;
}
.device-item-status img{
   width: 100%;
   height: 100%;
}
.scrollview-layout{
  margin-top: .25rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.audio-img{
  width: .375rem;
  height: .375rem;
  position: absolute;
  top: .25rem;
  right: 20px
}
.audio-img image{
  width: 100%;
  height: 100%;
}
.currentTime-layout{
  position: absolute;
  left: .375rem;
  top: .375rem;
}
.time-text{
  font-family: Microsoft YaHei;
  font-size: .4rem;
  color: #FFFFFF;
  line-height: .4rem;
}
</style>