<template>
  <!-- <dv-full-screen-container> -->
      <div class="login-content">
        <div class="logo-layout">
          <div class="logo"> </div>
          <div class="title"> 海都水产设备状态登录 </div>
        </div>
        <div class="login-layout">
          <div class="input-box">
            <input v-model="userName"  placeholder="请输入您的用户名" placeholder-class="input-placeholder" maxlength="11" @input="validatePhoneNumber"/>
          </div>
          <div class="input-box">
            <div class="code-input-box">
              <input placeholder="请输入您的验证码" placeholder-class="input-placeholder" v-model="codeValue" maxlength="6" @input="validateCodeNumber"/>
              <button class="code-btn" @click="getCodeAction" :class="userName.length == 11?'code-activate-btn':'code-diabled-btn'">
                  {{ codeTitle }}
              </button>
            </div>
          </div>
          <div class="login-btn-layout">
            <el-button  @click="LoginAction" class="login-btn" type="primary"  :disabled="isDisabled" >登录</el-button>
          </div>
        </div>
      </div>
  <!-- </dv-full-screen-container> -->
</template>
<script>
import  useStore  from '../store/user.js'
import { LoginApi,SendCodeApi } from '../api/api'
export default {
  name: 'LoginView',
  props: {

  },
  data(){
    return {
      userName:'',
      codeValue:'',
      isDisabled:true,
      codeTitle:'获取验证码',
      timeCount: 60,
      isFinish: false,
    }
  },
  created(){
     useStore().clearUserInfo();
  }, 
  methods:{
      // 登录
      LoginAction(){
        if(!this.validateMoblieNumber(this.userName)){
              this.$message('请正确输入手机号码');
              return;
        }
        const loading = this.$loading({
          lock: true,
          text: '登录...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        LoginApi({
          'mobile':this.userName,
          'smsCode':this.codeValue,
          'pushKey':'13124124124124',
          'loginMethod':'01'
        }).then((res)=>{
          console.log(res);
          loading.close();
          if(res.code === '00000'){
            this.$message({
              message: '登录成功',
              type: 'success'
             });
            let userInfo = JSON.parse(res.data);
            // 保存token
            useStore().setUserInfo(userInfo);
            this.$router.push('/home');
          }else{
            this.$message(res.msg);
          }
        })
      
      },
      //判断是否可以登录
      handleInputChange(){
        if(this.userName.length == 11 && this.codeValue.length == 6){
          this.isDisabled = false;
        }else{
          this.isDisabled = true;
        }
      },
      // 获取验证码
      getCodeAction(){
        if(this.isFinish) return;
        SendCodeApi({'mobile':this.userName}).then((res)=>{
          console.log(res);
          if(res?.code === '00000'){
            this.$message({
              message: '验证码发送成功',
              type: 'success'
             });
            this.countDown();
          }else{
            this.$message(res.msg);
          }
      })
      },
      // 开始倒计时
      countDown(){
        if(this.timeCount > 0){
          this.isFinish = true;
          this.timeCount--;
         setTimeout(this.countDown,1000);
          this.codeTitle = '重新发送('+this.timeCount+'s)';
         }else{
           this.isFinish = false;
           this.timeCount = 60;
          this.codeTitle = '获取验证码';
         }
      },
      validatePhoneNumber(event) {
      // 使用正则表达式来判断输入是否为正整数
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        this.userName = event.target.value.replace(/[^0-9]/g, '');
      }
      this.handleInputChange();
    },
    validateCodeNumber(event) {
      // 使用正则表达式来判断输入是否为正整数
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        this.codeValue = event.target.value.replace(/[^0-9]/g, '');
      }
      this.handleInputChange();
    },
    // 验证是否是数字
    validateMoblieNumber(phone){
        const regex = /^1[3-9]\d{9}$/;
        return regex.test(phone);
    }
  },
}
</script>

<style scoped>
.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
 
  margin: 0 auto;
  padding: 1.1rem 0.25rem 0;
}

.logo-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: .75rem;
}

.logo {
  width: 1.5375rem;
  height: 1.3625rem;
  background: url('../assets/img/logo.png') no-repeat 0 0 / cover;
  margin-bottom:.35rem;
}

.title {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size:.625rem;
  color: #FFFFFF;
  line-height: .625rem;
  background: linear-gradient(0deg, rgba(145, 220, 255, 0.07) 0%, rgba(109, 191, 233, 0) 50%, rgba(130, 204, 255, 0.07) 100%);
}

.login-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.65rem;
  height: 5.45rem;
  background: url('../assets/img/login_bg.png') no-repeat 0 0 / cover;
  padding: .925rem .6375rem;

}

.input-box {
  height: .875rem;
  background: #05376B;
  border-radius: .15rem;
  width: 100%;
  margin-bottom:.375rem;
  position: relative;
  display: flex;
  align-items: center;
  
}

.input-box:nth-child(1)::before {
  position: absolute;
  content: '';
  background: url('../assets/img/icon_code.png') 0 0 / cover;
  width:.35rem;
  height: .4125rem;
  left: .25rem;
  top: 50%;
  transform: translateY(-50%);
}

.input-box:nth-child(2)::before {
  position: absolute;
  content: '';
  background: url('../assets/img/icon_code.png') 0 0 / cover;
  width: .35rem;
  height: .4125rem;
  left: .25rem;
  top: 50%;
  transform: translateY(-50%);
}

input {
  width: 100%;
  outline: none;
  margin-left: .825rem;
  text-align: left;
  border: none;
  color: #fff;
  background: transparent;
  font-family: Source Han Sans CN;
  font-weight: 400;
  height: .875rem;
}

input::placeholder {
  height: .875rem;
  font-size: .275rem;
  color: #406892;
  text-align: left;
}

.code-input-box {
  width: 100%;
  display: flex;
  align-items: center;

}

.code-btn {
  width: 2.25rem;
  background: transparent;
  border: none;
  color: white;
  padding: .1875rem .4rem;
  text-align: center;
  text-decoration: none;
  font-size: .275rem;
  padding: 0 .25rem;
  white-space: nowrap;
}
.code-activate-btn{
  color: white;
  cursor: pointer;
}
.code-diabled-btn{
  color: gray;
  cursor: default;
}

.login-btn-layout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .375rem;
}

.login-btn {
  width: 100%;
  height: .875rem;
  border-radius: .15rem;
  color: #fff;
  font-size: .275rem;
  border: none;
  background-image: linear-gradient(to bottom, #0C89FF, #59D6FF);
  background-size: cover;
  background-repeat: no-repeat;
}

</style>